import React from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/dfw-studio-text-logo-white.png'


const Footer = () => {
  return (
    <footer className="section-footer">

      <div className="footer__social-box">
        <a className="footer__social-link" href="https://www.instagram.com/dfwstudio">Instagram</a> <br />
        <a className="footer__social-link" href="https://www.facebook.com/dfwstudio">Facebook</a>
      </div>

      <div className="footer__logo-box">
        <Link to="/">
          <img className="footer__logo" src={Logo} alt="dfw studio logo black text with icon" />
        </Link>
      </div>

      <div className="footer__copy-box">
        <p className="footer__copy-text">
           &copy; 2021 Copyright. Powered By
          <Link className="footer__copy-link" to="/">DFW Studio</Link>
        </p>
      </div>
    </footer>


  )
}

export default Footer