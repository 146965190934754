import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"


const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

const {
  defaultTitle,
  titleTemplate,
  defaultDescription,
  siteUrl,
  defaultImage,
  twitterUsername,
} = site.siteMetadata

const seo = {
  title: title || defaultTitle,
  description: description || defaultDescription,
  image: `${siteUrl}${image || defaultImage}`,
  url: `${siteUrl}${pathname}`
}

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article"  />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && <meta property="og:description" content={seo.description} />}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary-large-image" />
      
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && <meta name="twitter:description" content={seo.description} />}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "ProfessionalService",
            "name": "DFW Studio",
            "openingHours": "Mo-Su",
            "image": "/dfw-studio-logo-dfw-marketing-small-business-web-design-seo-agency.png",
            "address": {
            "@type": "PostalAddress",
            "addressLocality": "Fort Worth",
            "addressRegion": "TX",
                "postalCode": "76134"
          },
            "priceRange": "$",
            "telephone": "(817) 502-3879",
            "email": "mailto:info@dfw-studio.com",
            "paymentAccepted": "Cash, Credit Card, BTC",
            "currenciesAccepted": "USD",
            "url":"http://dfw-studio.com"
        }
      `}
    </script>

  <script type="text/javascript">
    {`
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "521m4ww3b4");
    `}
  </script>

  <script type="text/javascript">{`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"67d163451c448ba506770f4bdb4978d9a701bed8fe737eed0a4eb3b31c3a33d5", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
  `}
  </script>

    </Helmet>
  )
}

export default SEO

SEO.propTypes = {

  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,

}

SEO.defaultProps = {

  title: null,
  description: null,
  image: null,
  article: false,

}

const query = graphql`
query SEO {
  site {
    siteMetadata {
      defaultTitle: title
      titleTemplate
      defaultDescription: description
      siteURL : url
      defaultImage: image
      twitterUsername
    }
  }
}
`