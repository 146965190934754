import React from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/dfw-studio-logo-text-with-icon-grey.png'

export default function Header() {
  return (
    <header className="header">

      <div className="header__logo">
        <Link to="/" className="header__logo-link">
          <img className="header__logo-img" src={Logo} alt="dfw studio logo black text with icon" />
        </Link>
      </div>

      <input type="checkbox" id="toggle" className="header__toggle" />

      <label htmlFor="toggle" className="header__toggle-label">
        <span></span>
      </label>

      <nav className="header__nav">
        <ul className="header__nav-list">

          <li className="header__nav-items">
            <Link to="/" className="header__nav-links">Home</Link>
          </li>
          <li className="header__nav-items">
            <Link to="/services" className="header__nav-links">Services</Link>
          </li>
          <li className="header__nav-items">
            <Link to="/case-studies" className="header__nav-links">Case Studies</Link>
          </li>
          <li className="header__nav-items">
            <Link to="/contact" className="header__nav-links">Contact</Link>
          </li>

        </ul>
      </nav>

    </header>
  )
}